import { Button, Stack } from '@mui/material'
import React from 'react'

export default function RefreshButton(props: { requestKeys: () => void; loading: boolean }) {
  return (
    <Stack direction='row' alignItems='center'>
      <Button variant='outlined' disabled={props.loading} onClick={() => props.requestKeys()}>
        Refresh keys
      </Button>
    </Stack>
  )
}

import { Typography, Box } from '@mui/material'

interface IFieldKeyValue {
  keyValue: string
  value: string | number | null
}

export default function FieldKeyValue({ keyValue, value }: IFieldKeyValue) {
  return (
    <Typography>
      {keyValue}:{' '}
      <Box component='span' sx={{ opacity: 0.7 }}>
        {value ?? ''}
      </Box>
    </Typography>
  )
}

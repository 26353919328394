export const localStorageKeys = {
  username: 'USERNAME_V',
  refreshToken: 'RTOKEN_V',
  accessToken: 'ATOKEN_V'
}
export const validRoles = {
  USERSREAD: 'USERSREAD',
  USERSWRITE: 'USERSWRITE',
  ACCOUNTSREAD: 'ACCOUNTSREAD',
  ACCOUNTSWRITE: 'ACCOUNTSWRITE',
  ADMINFULLACCESS: 'ADMINFULLACCESS', // Allows modifing admin tool user roles
  ADMINBASICACCESS: 'ADMINBASICACCESS' // all basic roles, minus super admin stuff
}
export enum gemsUserGroups {
  ADMIN = 'ADMIN',
  OPS = 'OPS',
  DEV = 'DEV',
  SUPPORT = 'SUPPORT',
  SUPPORTREADONLY = 'SUPPORTREADONLY'
}
export const TextCopied = 'Copied'
export type IndexType = { [key: string]: string }
export const statusEnum: IndexType = { 0: 'Locked', 1: 'Open' }
export const keyProfileEnum: IndexType = {
  1: 'CounterSketch Studio',
  2: 'Romantic Creation',
  3: 'CounterSketch International',
  4: 'CounterSketch Bridal',
  5: 'CounterSketch Plus',
  8: 'CounterSketch Studio Trial'
}
export const userTypeEnum: IndexType = {
  1: 'Real Users',
  2: 'PreRelease',
  3: 'Training Machines',
  4: 'Early Access',
  5: 'Sales',
  6: 'Stuller',
  7: 'External Testers',
  8: 'InHouse Testers',
  9: 'IT Dev',
  10: 'Gods'
}
// TODO: Pending to use token.
export const xApiKey = 'Op6UnEqps21nbIoYtnPQw5PAX8icflXyWyxqLnD1'
type environmentType = 'local' | 'dev' | 'prod'
const env: environmentType = (process.env.REACT_APP_current_stage as environmentType) ?? 'dev'
// TODO This url must should be using domain
const BACKEND_URL_OBJ = {
  local: 'http://127.0.0.1:3000',
  dev: 'https://gz6r9v54bg.execute-api.us-east-1.amazonaws.com/dev',
  prod: 'https://gcn78lja41.execute-api.us-west-1.amazonaws.com/dev'
}
export const BACKEND_URL = BACKEND_URL_OBJ[env]

import React from 'react'
import { renderEnum } from '../../utils/keyCodes'
import { Typography } from '@mui/material'

interface IStatus {
  field: string
  value: Object | null
  fontSize?: string
}

export default function Status({ field, value, fontSize = '14px' }: IStatus) {
  const isOpen = value === null
  const color = isOpen ? '#00d900' : '#ff2929'
  const statusText = renderEnum(field, isOpen ? 1 : 0)
  return (
    <Typography component='span' sx={{ color, fontSize }}>
      {statusText}
    </Typography>
  )
}
